import React from "react";

class Svg extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <svg className="svg-legend">
        <symbol id="icon-blackjack" viewBox="0 0 102 70">
          <g>
            <g>
              <path fill="#14193E" d="M70.928,123.408c-0.396,0-0.792-0.052-1.179-0.154L9.42,107.199c-1.18-0.314-2.167-1.069-2.78-2.125
			c-0.612-1.057-0.777-2.288-0.463-3.469L32.178,3.897c0.533-2,2.349-3.397,4.416-3.397c0.396,0,0.792,0.052,1.178,0.154
			l60.331,16.055c1.18,0.314,2.167,1.069,2.779,2.125c0.612,1.056,0.776,2.288,0.463,3.468l-26.001,97.709
			C74.812,122.012,72.995,123.408,70.928,123.408z"/>
              <g>
                <path fill="#2F52A6" d="M36.594,1c0.347,0,0.699,0.044,1.05,0.138l60.33,16.055c2.173,0.578,3.466,2.809,2.888,4.981
				L74.86,119.883c-0.484,1.822-2.132,3.025-3.933,3.025c-0.347,0-0.699-0.044-1.05-0.138l-60.33-16.055
				c-2.173-0.578-3.466-2.809-2.888-4.981L32.662,4.025C33.147,2.203,34.793,1,36.594,1 M36.594,0L36.594,0
				c-2.293,0-4.308,1.549-4.898,3.768L5.694,101.478c-0.348,1.309-0.166,2.675,0.513,3.847c0.679,1.173,1.774,2.01,3.083,2.358
				l60.33,16.055c0.428,0.113,0.867,0.171,1.307,0.171c2.294,0,4.309-1.55,4.899-3.769l26.001-97.708
				c0.719-2.703-0.895-5.486-3.598-6.205L37.901,0.171C37.473,0.058,37.033,0,36.594,0L36.594,0z"/>
              </g>
            </g>

            <rect x="28.572" y="17.796" transform="matrix(0.9664 0.2572 -0.2572 0.9664 17.6564 -11.7684)" fill="#01040C" stroke="#2F52A6" strokeMiterlimit="10" width="50.498" height="87.646" />
            <text transform="matrix(0.9664 0.2572 -0.2572 0.9664 32.8408 9.98)" fill="#FFFFFF" fontFamily="'Centaur'" fontSize="11.1468">A</text>

            <text transform="matrix(-0.9664 -0.2572 0.2572 -0.9664 73.501 112.2041)" fill="#FFFFFF" fontFamily="'Centaur'" fontSize="11.1468">J</text>
            <polygon fill="#FFFFFF" points="36.04,20.274 32.51,23.602 30.774,18.873 34.631,15.632 	" />
            <polygon fill="#FFFFFF" points="76.982,103.595 73.452,106.923 71.715,102.193 75.573,98.953 	" />
          </g>
          <g>
            <g>
              <path fill="#14193E" d="M31.574,140.618c-2.057,0-3.871-1.388-4.41-3.375L0.66,39.669C0,37.237,1.441,34.721,3.873,34.06
			l60.248-16.365c0.393-0.107,0.797-0.161,1.2-0.161c2.057,0,3.869,1.388,4.409,3.375l26.504,97.574
			c0.66,2.433-0.781,4.949-3.214,5.609l-60.247,16.365C32.381,140.563,31.978,140.618,31.574,140.618z"/>
              <path fill="#2F52A6" d="M65.321,18.034c1.792,0,3.434,1.193,3.927,3.006l26.504,97.574c0.589,2.17-0.692,4.407-2.862,4.996
			l-60.247,16.365c-0.357,0.097-0.716,0.144-1.069,0.144c-1.793,0-3.436-1.193-3.928-3.006L1.143,39.539
			c-0.589-2.17,0.692-4.407,2.862-4.997l60.248-16.365C64.609,18.08,64.969,18.034,65.321,18.034 M65.321,17.034L65.321,17.034
			c-0.448,0-0.896,0.06-1.331,0.179L3.742,33.577c-2.698,0.733-4.297,3.525-3.564,6.224l26.504,97.573
			c0.599,2.204,2.61,3.744,4.893,3.744c0.448,0,0.896-0.061,1.331-0.179l60.247-16.365c2.698-0.732,4.298-3.523,3.565-6.223
			L70.213,20.777C69.614,18.573,67.603,17.034,65.321,17.034L65.321,17.034z"/>
            </g>

            <rect x="23.082" y="34.932" transform="matrix(0.965 -0.2621 0.2621 0.965 -18.9544 15.4232)" fill="#01040C" stroke="#2F52A6" strokeMiterlimit="10" width="50.498" height="87.646" />
            <g>
              <path fill="#FFFFFF" d="M4.311,37.595l-0.085-0.191c0.077-0.021,0.341-0.081,0.792-0.181c0.45-0.1,0.715-0.16,0.796-0.182
			c0.238-0.064,0.698-0.212,1.38-0.443l0.065,0.197c-0.315,0.108-0.529,0.209-0.642,0.304s-0.165,0.233-0.157,0.415
			c0.008,0.182,0.067,0.488,0.175,0.917c0.042,0.199,0.1,0.443,0.176,0.731c0.076,0.288,0.147,0.56,0.212,0.817l0.219,0.804
			c0.037,0.137,0.159,0.567,0.365,1.292c0.206,0.724,0.359,1.271,0.459,1.639c0.07,0.259,0.125,0.495,0.164,0.706
			c-0.187,0.518-0.399,0.993-0.639,1.427c-0.239,0.434-0.525,0.695-0.858,0.786c-0.077,0.021-0.161,0.016-0.252-0.017
			c-0.092-0.032-0.144-0.071-0.156-0.117c-0.017-0.063,0.049-0.172,0.199-0.327c0.15-0.154,0.259-0.241,0.329-0.259
			c0.021-0.006,0.128-0.001,0.321,0.014c0.438-0.119,0.399-1.124-0.114-3.015l-0.566-2.085c-0.238-0.875-0.434-1.558-0.588-2.049
			c-0.153-0.49-0.275-0.813-0.364-0.967c-0.089-0.154-0.19-0.254-0.304-0.298c-0.118-0.047-0.268-0.057-0.451-0.03
			S4.445,37.548,4.311,37.595z"/>
            </g>

            <text transform="matrix(-0.965 0.2621 -0.2621 -0.965 90.7266 112.6514)" fill="#FFFFFF" fontFamily="'Centaur'" fontSize="11.1468">J</text>
            <polygon fill="#FFFFFF" points="12.214,51.902 10.829,56.551 6.955,53.331 8.667,48.593 	" />
            <polygon fill="#FFFFFF" points="89.419,103.457 88.034,108.106 84.16,104.886 85.872,100.147 	" />
          </g>
        </symbol>
        <symbol id="icon-deal" viewBox="0 0 386 238.5">
          <g>
            <g>
              <path d="M245.907,58.437l-60.493,98.944l18.653,12.952l11.74-22.139c0,0,42.14-0.451,42.58,0c0.44,0.452,11.6,25,11.6,25
			l26.723-1.205L245.907,58.437z M227.848,128.466l14.535-25.752l11.894,26.656L227.848,128.466z"/>
              <path fill="#FFFFFF" d="M245.907,58.437l50.803,113.552l-26.723,1.205c0,0-11.159-24.548-11.6-25
			c-0.146-0.15-4.926-0.2-11.234-0.2c-12.617,0-31.346,0.2-31.346,0.2l-11.74,22.139l-18.653-12.952L245.907,58.437 M254.276,129.37
			l-11.894-26.656l-14.535,25.752L254.276,129.37 M246.869,43.256l-6.933,11.341l-60.493,98.944l-3.616,5.913l5.636,3.913
			l18.654,12.952l6.431,4.465l3.711-6.997l9.76-18.405c6.24-0.059,18.268-0.158,27.135-0.158c3.098,0,5.274,0.012,6.801,0.028
			c2.325,4.965,6.556,14.201,9.646,20.996l1.99,4.38l4.707-0.212l26.723-1.205l10.465-0.472l-4.369-9.765L252.313,55.422
			L246.869,43.256L246.869,43.256z M239.843,121.644l1.807-3.202l1.479,3.313L239.843,121.644L239.843,121.644z"/>
            </g>
          </g>
          <g>
            <polygon points="124.675,63.658 184.582,63.658 190.65,86.029 151.104,86.029 151.104,106.83 179.884,106.83 179.884,128.718 
		151.692,128.718 151.692,152.612 194.957,152.612 188.889,174.7 124.675,174.7 	"/>
            <path fill="#FFFFFF" d="M184.582,63.658l6.068,22.371h-39.546v20.801h28.779v21.888h-28.192v23.895h43.265l-6.068,22.088h-64.213
		V63.658H184.582 M189.937,56.429h-5.354h-59.907h-7.048v7.229V174.7v7.229h7.048h64.213h5.336l1.447-5.269l6.069-22.086l2.525-9.19
		h-9.31H158.74v-9.438h21.144h7.048v-7.229V106.83v-7.229h-7.048h-21.731v-6.344h32.498h9.276l-2.487-9.167l-6.067-22.371
		L189.937,56.429L189.937,56.429z"/>
          </g>
          <g>
            <polygon points="289.712,63.658 316.923,61.851 312.421,152.863 354.12,154.068 346.045,178.314 285.845,176.056 	" />
            <path fill="#FFFFFF" d="M316.923,61.851l-4.502,91.012l41.699,1.205l-8.075,24.246l-60.2-2.259l3.867-112.397L316.923,61.851
		 M324.363,54.113l-7.896,0.525l-27.212,1.807l-6.362,0.422l-0.226,6.536l-3.866,112.398l-0.249,7.214l7.034,0.264l60.199,2.26
		l5.234,0.196l1.693-5.084l8.075-24.247l3.093-9.285l-9.563-0.277l-34.493-0.995l4.137-83.629L324.363,54.113L324.363,54.113z"/>
          </g>
          <g>
            <g>
              <path d="M124.676,100.807c-15.27-47.991-61.081-39.357-61.081-39.357L27.377,66.67l15.662,111.645
			C106.078,170.483,139.946,148.797,124.676,100.807z M68.489,152.612l-12.726-65.46c5.286-1.205,40.134-6.626,48.356,24.498
			C112.342,142.773,68.489,152.612,68.489,152.612z"/>
              <path fill="#FFFFFF" d="M71.803,60.844c13.898,0,41.644,4.672,52.873,39.962c15.27,47.99-18.598,69.677-81.637,77.508
			L27.377,66.67l36.218-5.221C63.595,61.449,66.801,60.844,71.803,60.844 M68.489,152.612c0,0,43.853-9.839,35.63-40.963
			c-5.722-21.66-24.338-25.621-36.913-25.62c-5.494,0-9.836,0.756-11.443,1.123L68.489,152.612 M71.803,53.616
			c-5.004,0-8.442,0.541-9.306,0.692l-36.101,5.204l-7.002,1.009l1.007,7.179l15.662,111.644l0.982,6.997l6.842-0.85
			c44.545-5.534,72.119-17.753,84.299-37.355c8.322-13.393,9.395-30.071,3.19-49.572C118.468,57.996,85.414,53.616,71.803,53.616
			L71.803,53.616z M64.154,93.346c0.965-0.055,1.99-0.087,3.053-0.087c16.424-0.001,26.555,6.824,30.11,20.282
			c1.435,5.431,0.892,10.168-1.66,14.481c-4.488,7.584-14.399,12.674-21.767,15.41L64.154,93.346L64.154,93.346z"/>
            </g>
          </g>
          <g>
            <path fill="#3F5EAE" d="M382,4v230.557H4V4H382 M382,0H4C1.791,0,0,1.791,0,4v230.557c0,2.209,1.791,4,4,4h378c2.209,0,4-1.791,4-4
		V4C386,1.791,384.209,0,382,0L382,0z"/>
          </g>
        </symbol>

        <symbol id="icon-grid-blaster" viewBox="0 0 351 351">

          <g>
            <rect x="0.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="50.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="100.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="150.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="200.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="250.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="300.5" y="0.5" fill="none" strokeMiterlimit="10" width="50" height="350" />
            <rect x="0.5" y="0.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="50.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="100.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="150.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="200.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="250.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
            <rect x="0.5" y="300.5" fill="none" strokeMiterlimit="10" width="350" height="50" />
          </g>
          <circle fill="#BC85BC" stroke="#3E71BB" strokeMiterlimit="10" cx="33.667" cy="169.25" r="8.625" />
          <circle fill="#BC85BC" stroke="#3E71BB" strokeMiterlimit="10" cx="23.417" cy="184.125" r="8.625" />
          <circle fill="#BC85BC" stroke="#3E71BB" strokeMiterlimit="10" cx="15.917" cy="168" r="8.625" />
          <circle fill="#787878" stroke="#2A4483" strokeWidth="2" strokeMiterlimit="10" cx="325.5" cy="176.917" r="18.5" />
        </symbol>
        <symbol id="icon-game" viewBox="0 0 59 59">
          <path d="M44.246,27.028H31v-11.5c0-1.93,1.57-3.5,3.5-3.5s3.5,1.57,3.5,3.5v3.5c0,2.757,2.243,5,5,5s5-2.243,5-5v-16
			c0-0.553-0.448-1-1-1s-1,0.447-1,1v16c0,1.654-1.346,3-3,3s-3-1.346-3-3v-3.5c0-3.032-2.467-5.5-5.5-5.5s-5.5,2.468-5.5,5.5v11.5
			H14.972C6.716,27.028,0,33.743,0,42c0,7.149,5.209,14.872,12.963,14.972c0.069,0.001,0.14,0.001,0.213,0.001
			c2.072,0,5.679-0.354,9.378-1.367c4.359-1.194,9.764-1.195,14.107-0.001c3.952,1.087,8.169,1.381,9.596,1.367l0,0
			C52.386,56.867,59,51.112,59,42C59,33.743,52.381,27.028,44.246,27.028z M46.225,54.971c-1.278,0.021-5.327-0.276-9.033-1.295
			c-4.669-1.285-10.481-1.284-15.166-0.001c-3.629,0.994-7.12,1.317-9.038,1.296C7.682,54.903,2,49.674,2,42
			c0-7.152,5.819-12.972,12.972-12.972h29.274C51.278,29.028,57,34.847,57,42C57,48.174,52.67,54.861,46.225,54.971z"/>
          <path d="M33.631,32.028c0-0.553-0.448-1-1-1H28c-0.552,0-1,0.447-1,1c0,0.553,0.448,1,1,1h4.631
			C33.184,33.028,33.631,32.581,33.631,32.028z"/>
          <path d="M37,38.028c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S39.206,38.028,37,38.028z M37,44.028c-1.103,0-2-0.897-2-2
			s0.897-2,2-2s2,0.897,2,2S38.103,44.028,37,44.028z"/>
          <path d="M51,38.028c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S53.206,38.028,51,38.028z M51,44.028c-1.103,0-2-0.897-2-2
			s0.897-2,2-2s2,0.897,2,2S52.103,44.028,51,44.028z"/>
          <path d="M44,45.028c-2.206,0-4,1.794-4,4s1.794,4,4,4s4-1.794,4-4S46.206,45.028,44,45.028z M44,51.028c-1.103,0-2-0.897-2-2
			s0.897-2,2-2s2,0.897,2,2S45.103,51.028,44,51.028z"/>
          <path d="M44,39.028c2.206,0,4-1.794,4-4s-1.794-4-4-4s-4,1.794-4,4S41.794,39.028,44,39.028z M44,33.028c1.103,0,2,0.897,2,2
			s-0.897,2-2,2s-2-0.897-2-2S42.897,33.028,44,33.028z"/>
          <path d="M23,38.028h-3v-3c0-0.553-0.448-1-1-1h-6c-0.552,0-1,0.447-1,1v3H9c-0.552,0-1,0.447-1,1v6c0,0.553,0.448,1,1,1h3v3
			c0,0.553,0.448,1,1,1h6c0.552,0,1-0.447,1-1v-3h3c0.552,0,1-0.447,1-1v-6C24,38.474,23.552,38.028,23,38.028z M22,44.028h-3
			c-0.552,0-1,0.447-1,1v3h-4v-3c0-0.553-0.448-1-1-1h-3v-4h3c0.552,0,1-0.447,1-1v-3h4v3c0,0.553,0.448,1,1,1h3V44.028z"/>


        </symbol>
        <symbol id="icon-calculator" viewBox="0 0 251 311.5">
          <path d="M234,311.5H17c-9.374,0-17-7.626-17-17V17C0,7.626,7.626,0,17,0h217c9.374,0,17,7.626,17,17v277.5
            C251,303.874,243.374,311.5,234,311.5z M17,10c-3.86,0-7,3.14-7,7v277.5c0,3.859,3.14,7,7,7h217c3.859,0,7-3.141,7-7V17
            c0-3.86-3.141-7-7-7H17z"/>
          <path d="M203.25,89.25H46v-48h157.25V89.25z M56,79.25h137.25v-28H56V79.25z" />
          <rect x="44.25" y="106.75" width="41.5" height="41.5" />
          <rect x="44.25" y="165.25" width="41.5" height="41.5" />
          <rect x="44.25" y="223.75" width="41.5" height="41.5" />
          <rect x="103.875" y="106.75" width="41.5" height="41.5" />
          <rect x="164.875" y="106.75" width="41.5" height="41.5" />
          <rect x="164.875" y="165.25" width="41.5" height="100" />
          <rect x="103.875" y="165.25" width="41.5" height="41.5" />
          <rect x="103.875" y="223.75" width="41.5" height="41.5" />
        </symbol>
        <symbol id="icon-clock" viewBox="0 0 193 193">
          <path
            d="M96.5,193C43.29,193,0,149.71,0,96.5C0,43.29,43.29,0,96.5,0C149.709,0,193,43.29,193,96.5C193,149.71,149.709,193,96.5,193
	z M96.5,7C47.149,7,7,47.149,7,96.5C7,145.851,47.149,186,96.5,186c49.35,0,89.5-40.148,89.5-89.5C186,47.149,145.85,7,96.5,7z"
          />
          <polygon points="150.712,103.5 92.5,103.5 92.5,26.833 99.66,26.833 99.66,96.34 150.712,96.34 " />
        </symbol>
        <symbol id="icon-stock" viewBox="0 0 864 864">
          <g id="Layer_2">
            <rect x="729.187" y="282.101" width="94.244" height="508.899" />
            <rect x="617.044" y="332.99" width="94.242" height="458.01" />
            <rect x="504.899" y="446.08" width="94.243" height="344.92" />
            <rect x="392.755" y="527.124" width="94.243" height="263.876" />
            <rect x="280.611" y="479.061" width="94.242" height="311.939" />
            <rect x="168.466" y="621.366" width="94.242" height="169.634" />
            <rect x="56.323" y="643.984" width="94.242" height="147.016" />
            <polygon
              points="79.871,511.104 313.588,287.755 453.065,377.282 669.816,201.054 722.589,259.482 800.813,71.001 599.143,101.159 
		636.834,152.99 454.949,300.946 302.278,212.362 38.403,473.407 	"
            />
          </g>
        </symbol>
        <symbol id="icon-weather" viewBox="0 0 230.777 211.479">
          <g id="Layer_2" className="icon-weather__cloud">
            <path
              d="M168.056,211.479H62.833c-20.396,0-36.06-4.115-46.558-12.231C5.476,190.898,0,178.359,0,161.979
		c0-26.903,22.546-37.611,31.104-40.705c-1.509-10.478,1.724-26.819,10.531-39.294c8.357-11.837,20.453-18.578,34.979-19.494
		c1.65-0.104,3.289-0.157,4.869-0.157c26.345,0,36.794,14.607,41.817,21.626c0.709,0.992,1.556,2.175,2.105,2.791
		c0.415-0.19,1.069-0.553,1.584-0.837c2.715-1.501,6.815-3.771,13.761-3.771c5.908,0,12.695,1.616,20.752,4.939
		c24.665,10.174,21.526,40.685,19.731,50.82c6.86,2.589,21.434,11.038,21.434,36.082c0,32.703-34.046,37.428-34.39,37.472
		L168.056,211.479z M81.482,69.329c-1.435,0-2.924,0.048-4.429,0.143c-12.514,0.789-22.506,6.356-29.7,16.545
		c-8.964,12.696-10.882,29.452-8.821,36.666l1.001,3.506l-3.543,0.857C34.813,127.336,7,134.501,7,161.979
		c0,28.598,18.263,42.5,55.833,42.5h104.755c2.757-0.412,28.078-4.905,28.078-30.5c0-26.489-18.316-30.078-19.096-30.218
		l-3.679-0.66l0.877-3.631c0.088-0.358,8.604-36.212-14.938-45.923c-7.093-2.926-13.177-4.41-18.082-4.41
		c-5.138,0-8.04,1.606-10.372,2.896c-1.709,0.946-3.322,1.839-5.307,1.839c-0.438,0-0.877-0.046-1.304-0.138
		c-2.455-0.526-4.01-2.699-6.161-5.706C112.603,81.034,104.228,69.329,81.482,69.329z"
            />
            <rect
              x="79.905"
              y="36.145"
              transform="matrix(0.7833 0.6217 -0.6217 0.7833 44.1406 -47.3319)"
              width="20.108"
              height="7"
            />
            <rect
              x="109.29"
              y="13.145"
              transform="matrix(0.3565 0.9343 -0.9343 0.3565 92.4247 -100.8989)"
              width="20.336"
              height="7"
            />
            <rect
              x="152.834"
              y="0.324"
              transform="matrix(0.9936 0.1129 -0.1129 0.9936 2.285 -17.5719)"
              width="7"
              height="22.142"
            />
            <rect
              x="186.959"
              y="13.07"
              transform="matrix(0.7947 0.607 -0.607 0.7947 53.9138 -110.6053)"
              width="7"
              height="22.651"
            />
            <rect
              x="211.709"
              y="43.792"
              transform="matrix(0.3524 0.9358 -0.9358 0.3524 190.9747 -165.6907)"
              width="7"
              height="22.706"
            />
            <rect
              x="207.608"
              y="89.395"
              transform="matrix(0.9802 0.1982 -0.1982 0.9802 22.7602 -41.5614)"
              width="22.7"
              height="7"
            />
            <rect
              x="194.458"
              y="123.895"
              transform="matrix(0.7928 0.6094 -0.6094 0.7928 120.109 -98.5542)"
              width="21.126"
              height="7"
            />
            <g>
              <path
                d="M189.844,103.229c3.228-5.834,4.864-12.844,4.864-20.834c0-25.916-21.084-47-47-47c-16.23,0-30.992,8.153-39.676,21.83
			l5.879,3.78c7.389-11.662,19.972-18.61,33.797-18.61c22.056,0,40,17.944,40,40c0,6.127-1.093,11.504-3.251,16.011
			c-0.237-0.328-0.489-0.698-0.746-1.083l4.18,8.264C188.57,105.069,189.242,104.319,189.844,103.229z"
              />
            </g>
          </g>
        </symbol>
        <symbol id="icon-ring" viewBox="0 -2 100 104">
          <circle cx="50" cy="50" r="50" />
        </symbol>
        <symbol id="icon-lock" viewBox="0 0 538.973 538.846">
          <g>
            <path
              className="security__path security__path--1"
              d="M447.571,103.567l18.478-18.478c-1.97-2.097-3.963-4.178-6.007-6.222C410.372,29.197,344.726,1.318,274.691,0v26.002
			C342.847,27.44,404.193,57.021,447.571,103.567z"
            />
            <path
              className="security__path security__path--3"
              d="M435.343,447.508l18.478,18.478c2.098-1.97,4.178-3.963,6.222-6.007c45.009-45.008,72.12-103.138,77.796-165.761h-26.119
			C505.595,354.494,477.383,408.328,435.343,447.508z"
            />
            <path
              className="security__path security__path--2"
              d="M473.044,92.817l-18.524,18.524c36.421,42.569,58.453,97.798,58.453,158.082c0,4.83-0.158,9.623-0.437,14.385h26.052
			c0.249-4.773,0.385-9.568,0.385-14.385C538.973,203.831,515.688,141.822,473.044,92.817z"
            />
            <path
              className="security__path security__path--6"
              d="M27.254,294.218H1.135c5.676,62.623,32.787,120.753,77.796,165.761c2.044,2.044,4.125,4.037,6.222,6.007l18.478-18.478
			C61.59,408.328,33.378,354.494,27.254,294.218z"
            />
            <path
              className="security__path security__path--5"
              d="M92.88,472.981c47.707,41.515,107.739,64.667,171.402,65.865v-26.003c-58.255-1.229-111.537-23.017-152.877-58.387
			L92.88,472.981z"
            />
            <path
              className="security__path security__path--7"
              d="M26,269.423c0-60.285,22.032-115.514,58.454-158.083L65.929,92.816C23.285,141.822,0,203.831,0,269.423
			c0,4.816,0.135,9.611,0.385,14.385h26.052C26.158,279.046,26,274.253,26,269.423z"
            />
            <path
              className="security__path security__path--4"
              d="M274.691,512.843v26.003c63.663-1.198,123.695-24.352,171.401-65.866l-18.524-18.524
			C386.229,489.826,332.946,511.615,274.691,512.843z"
            />
            <path
              className="security__path security__path--8"
              d="M264.282,26.002V0C194.248,1.318,128.601,29.197,78.931,78.867c-2.044,2.044-4.037,4.125-6.007,6.222l18.478,18.478
			C134.78,57.021,196.126,27.44,264.282,26.002z"
            />
            <path
              d="M87.309,269.423c0-7.939,0.511-15.76,1.496-23.431l-14.299-6.163c-1.462,9.729-2.197,19.606-2.197,29.594
			c0,26.612,5.215,52.437,15.501,76.755c9.932,23.481,24.147,44.567,42.25,62.671c5.711,5.711,11.721,11.033,18.007,15.958
			l6.164-14.302C113.385,377.097,87.309,326.308,87.309,269.423z"
            />
            <path
              d="M408.912,408.849c5.711-5.711,11.033-11.721,15.958-18.007l-14.302-6.164
			c-33.408,40.846-84.197,66.923-141.082,66.923c-7.939,0-15.759-0.511-23.43-1.495l-6.163,14.299
			c9.728,1.461,19.604,2.196,29.593,2.196c26.612,0,52.437-5.216,76.755-15.501C369.723,441.167,390.809,426.953,408.912,408.849z"
            />
            <path
              d="M451.664,269.423c0,7.939-0.511,15.759-1.495,23.43l14.299,6.163c1.461-9.728,2.196-19.604,2.196-29.593
			c0-26.612-5.216-52.436-15.501-76.755c-9.933-23.481-24.147-44.567-42.251-62.671c-5.711-5.711-11.72-11.033-18.006-15.958
			l-6.164,14.302C425.588,161.75,451.664,212.539,451.664,269.423z"
            />
            <path
              d="M269.486,87.246c7.939,0,15.76,0.511,23.431,1.496l6.163-14.299c-9.729-1.462-19.605-2.197-29.594-2.197
			c-26.612,0-52.436,5.215-76.755,15.501c-23.481,9.932-44.567,24.147-62.671,42.25c-5.711,5.711-11.033,11.72-15.957,18.006
			l14.301,6.164C161.813,113.322,212.602,87.246,269.486,87.246z"
            />
          </g>
        </symbol>
        <symbol id="icon-fingerprint" viewBox="0 0 24 24">
          <title>Fingerprint</title>
          <path d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39-2.57 0-4.66 1.97-4.66 4.39 0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94 1.7 0 3.08 1.32 3.08 2.94 0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z" />
        </symbol>
      </svg>
    );
  }
}
export default Svg;
